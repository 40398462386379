
import { logger } from '../../../support'
import { ShippingTimerData } from '../../products/organisms/ProductHeader'

export interface DeliveryTimerHelperData {
  shouldShowTimer: boolean
  maxOrderHours: number | null
}

/**
 * Helper function capable of deciding whether to show delivery timer based on input data.
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @param shippingTimer
 */
export const showDeliveryTimer = (
  shippingTimer: ShippingTimerData | undefined
): DeliveryTimerHelperData => {
  if (!shippingTimer) {
    return {
      shouldShowTimer: false,
      maxOrderHours: null
    }
  }

  const now = Date.now()
  const { limitHours, showTimer, firstDisabledDay, lastDisabledDay } = shippingTimer
  const limit = new Date()
  let shouldShowTimer = showTimer
  const maxOrderHours = limitHours ? parseInt(limitHours) : null

  // Calculate limit time in milliseconds
  limit.setHours(parseFloat(limitHours))
  limit.setMinutes(0)
  limit.setSeconds(0)

  // Decide whether to show times based on limit hour
  if (showTimer) {
    shouldShowTimer = now < limit.getTime()
  }

  // ----- START -----
  // Decide whether to show timer.
  // Take into account 3 conditions:
  // --- 1. Boolean value that decided whether to show timer
  // --- 2. Maximum hour until which timer should be visible
  // --- 3. Specific `start` and `end` date when timer should not be visible
  let firstDayLimitTime
  let lastDayLimitTime

  if (firstDisabledDay) {
    const firstDayLimit = new Date(firstDisabledDay)
    firstDayLimit.setHours(0)
    firstDayLimit.setMinutes(0)
    firstDayLimit.setSeconds(1)

    firstDayLimitTime = firstDayLimit.getTime()
  }

  if (lastDisabledDay) {
    const lastDayLimit = new Date(lastDisabledDay)
    lastDayLimit.setHours(23)
    lastDayLimit.setMinutes(59)
    lastDayLimit.setSeconds(59)

    lastDayLimitTime = lastDayLimit.getTime()
  }

  if (firstDayLimitTime && lastDayLimitTime) {
    if (lastDayLimitTime < firstDayLimitTime) { // Check if input is INVALID!
      shouldShowTimer = false
      logger('[delivery-timer.ts] Invalid input! `lastDisabledDay` date can not be less than `firstDisabledDay`.')
      return {
        shouldShowTimer,
        maxOrderHours: null
      }
    }

    if (showTimer) {
      shouldShowTimer = now < firstDayLimitTime || now > lastDayLimitTime
    } else {
      shouldShowTimer = false
    }
  } else if (firstDayLimitTime && !lastDayLimitTime) { // Check if there is only `START` date
    if (showTimer) {
      shouldShowTimer = now < firstDayLimitTime
    } else {
      shouldShowTimer = false
    }
  } else if (!firstDayLimitTime && lastDayLimitTime) { // Check if there is only `END` date
    if (showTimer) {
      shouldShowTimer = now > lastDayLimitTime
    } else {
      shouldShowTimer = false
    }
  }
  // ----- END -----

  return {
    shouldShowTimer,
    maxOrderHours
  }
}
